@font-face {
    font-family: "Neuralspace-Light";
    src: local("Neuralspace"), url("./NS-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Neuralspace-Regular";
    src: local("Neuralspace"), url("./NS-Regular.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Neuralspace-Medium";
    src: local("Neuralspace"), url("./NS-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Neuralspace-Bold";
    src: local("Neuralspace"), url("./NS-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

/* Code snippet font*/

@font-face {
    font-family: "Neuralspace-Code-Light";
    src: local("Neuralspace"), url("./ns-code/NS-Code-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Neuralspace-Code-Regular";
    src: local("Neuralspace"), url("./ns-code/NS-Code-Regular.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Neuralspace-Code-Medium";
    src: local("Neuralspace"), url("./ns-code/NS-Code-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Neuralspace-Code-SemiBold";
    src: local("Neuralspace"), url("./ns-code/NS-Code-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Neuralspace-Code-Bold";
    src: local("Neuralspace"), url("./ns-code/NS-Code-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}